<script> 
</script>

<div class="promo"></div>

<style>
    .promo {
        background-color: #A0BD3B;
        height: 30px;
        color: var(--pure-white);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
    }
</style>
