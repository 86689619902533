<script>
	import '../app.css';
	import Header from '$lib/header/header.svelte';
	import Footer from '$lib/footer/_Footer.svelte';
	import { page, navigating } from '$app/stores';
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';

	$: showSpinner = renderHeader && $navigating?.type === 'link';

	$: renderHeader = !$page.url.pathname.includes('/admin');

	onMount(() => {
		let gtagScript = document.createElement('script');
		gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-WJY1SH3Z9B';
		gtagScript.async = true;
		document.head.appendChild(gtagScript);

		gtagScript.onload = () => {
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', 'G-WJY1SH3Z9B');
			console.log("GA4 Started")
		};
	});

</script>

<svelte:head>
	<link rel="canonical" href="{import.meta.env.VITE_URL}{$page.url.pathname}" />
	<meta property="og:url" content="{import.meta.env.VITE_URL}{$page.url.pathname}"/>
	<meta property="og:type" content="website" />
	<meta property="og:image" content={`${import.meta.env.VITE_URL}/og-base.webp`} />
	<meta property="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content="communitylands.com" />
	<meta property="twitter:url" content={import.meta.env.VITE_URL} />
	<meta property="twitter:image" content={`${import.meta.env.VITE_URL}/og-base.webp`} />
	<script src="/scripts/hotjar.js"></script>
	<script src="/scripts/gtm.js"></script>
	

</svelte:head>

<!-- <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WH9VZF5"
	height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> -->
{#if renderHeader}
	<Header />
{/if}
{#if showSpinner}
	<div transition:fade={{ duration: 100 }} class="wrap">
		<div class="lds-dual-ring" />
	</div>
{/if}
<div class="main">
	<slot />
</div>
{#if renderHeader}
	<Footer />
{/if}

<style>
	.main {
		flex: 1;
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		min-height: calc(100vh - 400px);
		position: relative;
	}

	.wrap {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2000;
		display: flex;
		width: 100vw;
		height: 100vh;
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(3px);
		background-color: #00000010;
	}

	.lds-dual-ring {
		display: inline-block;
		width: 80px;
		height: 80px;
	}
	.lds-dual-ring:after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid var(--primary-color);
		border-color: var(--primary-color) transparent var(--primary-color) transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@font-face {
		font-family: 'Made Tommy Soft';
		src: url("/made_tommy_soft/MADE Tommy Soft Regular PERSONAL USE.otf") format('opentype');
		font-weight: normal;
		font-style: normal;
	}
</style>
