export const mainItems = [
	{
		priority: 1,
		title: "LAND MARKETPLACE",
		subMenu: true,
		subItems: [
			{
				title: "AVAILABLE",
				target: "/properties"
			},
			{
				title: "IN CONTRACT",
				target: "/properties/in-contract"
			},
			{
				title: "SOLD",
				target: "/properties/sold"
			}
		]
	},
	{
		priority: 2,
		title: "BUYING PROCESS",
		target: "/buying-process"
	},

	{
		priority: 3,
		title: "EDUCATION",
		target: '/communitychronicle'
	}
	// {
	// 	priority: 4,
	// 	title: "COMMUNITY",
	// 	target: "#"
	// }
	// {
	// 	priority: 3,
	// 	title: "FAQs",
	// 	target: "/faqs"
	// },
	// {
	// 	priority: 4,
	// 	title: "Contact Us",
	// 	target: "/contact-us"
	// },
	// {
	// 	priority: 5,
	// 	title: "Community Chronicle",
	// 	target: "/communitychronicle"
	// },
	// {
	// 	priority: 6,
	// 	title: "About Us",
	// 	target: "/our-purpose"
	// },
	// {
	// 	priority: 7,
	// 	title: "Testimonials",
	// 	target: "#"
	// },
	// {
	// 	priority: 8,
	// 	title: "Privacy",
	// 	target: "/privacy"
	// },
	// {
	// 	priority: 9,
	// 	title: "Terms",
	// 	target: "/terms"
	// },
	// {
	// 	priority: 10,
	// 	title: "Our Story",
	// 	target: "/our-story"
	// }
]
